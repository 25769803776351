/** @format */

import React, { useEffect } from "react";
import { MainLayout } from "components/layout";
import { ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import EventList from "../components/EventList";

export const Events = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <MainLayout>
        <div className="text-red-700 uppercase text-center text-5xl md:text-7xl md:text-largest font-bold pt-4 md:pt-10 pb-2">
          {t("events")}
        </div>
        <div className="bg-red-700 px-4 text-white text-4xl md:text-6xl font-bold flex flex-row space-x-4 items-center">
          <span>{t("latest")}</span> <ArrowRight />
        </div>
        <EventList />
      </MainLayout>
    </>
  );
};
