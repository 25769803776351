import React from "react";
import { Link } from "react-router-dom";
import img1 from "img/events/img1.jpeg";
import { useTranslation } from "react-i18next";

const EventList = () => {
  const { t } = useTranslation();

  return (
    <div className="border border-8 border-gray-900">
      <figure className="relative transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0">
        <Link to="/latest-events/kampala-uganda">
          <img
            style={{
              height: "50vh",
              width: "100%",
              objectFit: "cover",
            }}
            src={img1}
            alt=" description"
          />
          <figcaption className="absolute bottom-10 px-4 text-lg text-white">
            <span className="font-bold text-md md:text-semi-large">
              {t("kampalaCleanUp")}
            </span>
            <br />
            <span className="font-bold text-large">
              Kikaya-Kikulu-Ddungu (LC1) Kisaasi, Kampala, Uganda
            </span>
          </figcaption>
        </Link>
      </figure>
    </div>
  );
};

export default EventList;
