import React from "react";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";

export const MainLayout = ({ children }) => {
  return (
    <div className="bg-red-100">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};
