import React from "react";

export const GalleryImage = ({ src, alt, dataModalToggle }) => {
  return (
    <button type="button" data-modal-toggle={dataModalToggle}>
      <img
        className="rounded-md shadow-sm mb-6 transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0"
        src={src}
        alt={alt}
      />
    </button>
  );
};
