import React, { useEffect } from "react";

import img1 from "img/events/img1.jpeg";
import img2 from "img/events/img2.jpeg";
import img3 from "img/events/img3.jpeg";
import img4 from "img/events/img4.jpeg";
import img5 from "img/events/img5.jpeg";
import img6 from "img/events/img6.jpeg";
import img7 from "img/events/img7.jpeg";
import img8 from "img/events/img8.jpeg";
import img9 from "img/events/img9.jpeg";
import img10 from "img/events/img10.jpeg";
import img11 from "img/events/img11.jpeg";
import img12 from "img/events/img12.jpeg";
import img13 from "img/events/img13.jpeg";
import img14 from "img/events/img14.jpeg";
import img15 from "img/events/img15.jpeg";
import { useTranslation } from "react-i18next";
import { MainLayout } from "components/layout";
import Masonry from "react-masonry-css";
import { GalleryImage } from "components/elements";

export const Event = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout>
      <div className="text-red-700 uppercase text-center text-5xl md:text-7xl font-bold pt-4 md:pt-10 pb-2">
        {t("kampalaCleanUp")}
      </div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid p-8"
        columnClassName="my-masonry-grid_column"
      >
        <GalleryImage dataModalToggle="img1" src={img1} alt="Event" />
        <GalleryImage src={img2} alt="Event" />
        <GalleryImage src={img3} alt="Event" />
        <GalleryImage src={img4} alt="Event" />
        <GalleryImage src={img5} alt="Event" />
        <GalleryImage src={img6} alt="Event" />
        <GalleryImage src={img7} alt="Event" />
        <GalleryImage src={img8} alt="Event" />
        <GalleryImage src={img9} alt="Event" />
        <GalleryImage src={img10} alt="Event" />
        <GalleryImage src={img11} alt="Event" />
        <GalleryImage src={img12} alt="Event" />
        <GalleryImage src={img13} alt="Event" />
        <GalleryImage src={img14} alt="Event" />
        <GalleryImage src={img15} alt="Event" />
      </Masonry>

      <div
        id="img1"
        tabindex="-1"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
      >
        <div class="relative p-4 w-full max-w-7xl h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="extralarge-modal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="p-6 space-y-6">
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
