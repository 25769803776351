export const TRANSLATIONS_EN = {
  // languages
  en: "English",
  sw: "Swahili",
  bulungiBwansi: "Bulungi Bwansi",
  keepYourCityClean: "Keep Your City Clean",
  registerForEvent: "Register For Event",
  pleaseCheckEmail: "Please check your email and confirm your spot.",
  justASec: "Just a sec",
  cancel: "Cancel",
  register: "Register",
  registerForEvent: "Register For Event",
  name: "Name",
  email: "Email",
  phone: "Phone",
  kampalaCleanUp: "Kampala Clean-Up",
  events: "Events",
  latest: "Latest",
  event: "Event",
};
