import { Events, Event } from "features/events";
import { ConfirmEvent, Landing } from "features/landing";
import { NotFound } from "features/notfound";

export const publicRoutes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/attend/confirm/:token",
    element: <ConfirmEvent />,
  },
  {
    path: "/latest-events",
    element: <Events />,
  },
  {
    path: "/latest-events/kampala-uganda",
    element: <Event />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
