/** @format */

import React from "react";
import Noggles from "img/noggle.png";
import Africa from "img/africa-red.png";
import MastersLogo from "img/masters-logo.png";

export const Footer = () => {
  return (
    <div className="bg-black pt-20 pb-5 px-20">
      <div className="grid gap-24 md:flex md:justify-between">
        <a
          className=""
          href="https://discord.gg/6dFHRqDs"
          target="_blank"
          rel="noreferrer"
        >
          <section className="flex flex-row items-center mb-0">
            <span
              className="text-white font-bold"
              style={{
                fontSize: "5rem",
              }}
            >
              Nouns
            </span>
            <div className="rotate-30 -ml-2 mt-9">
              <img src={Africa} alt="Africa" className="h-14 " />
            </div>
          </section>
          <section className="flex flex-row -mt-14 items-center">
            <span
              className="text-white uppercase flex flex-col items-start text-2xl font-bold"
              // style={{
              //   fontSize: "2rem",
              // }}
            >
              <div className="-mt-9">in</div>
            </span>
            <span
              className="text-white font-bold"
              style={{
                fontSize: "5rem",
              }}
            >
              Africa
            </span>
          </section>
        </a>
        <a
          className=""
          href="https://nouns.wtf"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Noggles} alt="Noggles" className="h-24" />
          <span
            className="text-white font-bold text-5xl ml-16"
            // style={{
            // 	fontSize: "3rem",
            // }}
          >
            nouns.wtf
          </span>
        </a>
      </div>
      <div className="flex flex-row items-center justify-center">
        <a href="http://mastersug.com/" target="_blank" rel="noreferrer">
          <img src={MastersLogo} alt="Masters Studio" className="h-24 inline" />
        </a>
      </div>
    </div>
  );
};
