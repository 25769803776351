export const TRANSLATIONS_SW = {
  // languages
  en: "Kiingereza",
  sw: "Swahili",
  bulungiBwansi: "Harambee",
  keepYourCityClean: "Hifadhi mji wako safi",
  registerForEvent: "Jisajili kwa tukio",
  pleaseCheckEmail:
    "Tafadhali angalia barua pepe yako na ushahidi nafasi yako.",
  justASec: "Kwa sekunde chache",
  cancel: "Ghairi",
  register: "Jisajili",
  registerForEvent: "Jisajili kwa tukio",
  name: "Jina",
  email: "Barua pepe",
  phone: "Simu",
  kampalaCleanUp: "Usafishaji wa Kampala",
  events: "Matukio",
  latest: "Karibuni",
  event: "Tukio",
};
