/** @format */

import React, { useEffect } from "react";
import Noun444 from "img/noun-444.png";
import Noun445 from "img/noun-445.png";
import Noun446 from "img/noun-446.png";
import { MainLayout } from "components/layout";
import * as z from "zod";
import { useSubscribeToEvent } from "../api/subscribeToEvent";
import { Form, InputField } from "components/form";
import { Spinner } from "flowbite-react";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import img1 from "img/events/img1.jpeg";
import { Link } from "react-router-dom";

const schema = z.object({
  name: z.string().min(1, "Required"),
  email: z.string().min(1, "Required"),
  phone: z.string().min(1, "Required"),
});

export const Landing = () => {
  const [showModal, setShowModal] = React.useState(false);

  const subscribeToEventMutation = useSubscribeToEvent();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <MainLayout>
        <div
          className="text-red-700 uppercase text-center text-6xl md:text-largest font-bold pt-4 md:pt-10 pb-2"
          // style={{
          // 	fontSize: "10rem",
          // }}>
        >
          {t("bulungiBwansi")}
        </div>
        <div
          className="text-red-700 text-center text-5xl md:text-semi-large font-bold"
          // style={{
          // 	fontSize: "7rem",
          // }}
        >
          {t("keepYourCityClean")}
        </div>
        <div className="my-10 flex flex-row justify-center">
          <button
            onClick={() => setShowModal(true)}
            className="bg-white p-10 rounded-full text-2xl font-bold px-20"
          >
            {t("registerForEvent")}
          </button>
          {/* Modal  */}

          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-11/12 md:w-5/12 my-6 md:mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">
                        {t("registerForEvent")}
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}

                    {subscribeToEventMutation.isSuccess ? (
                      <div className="h-80 flex flex-col items-center space-y-4 justify-center">
                        <div className="flex flex-row items-center bg-green-100 justify-center rounded-full p-4">
                          <Check className="text-green-400" />
                        </div>
                        <span className="text-red-700">
                          {t("pleaseCheckEmail")}
                        </span>
                      </div>
                    ) : (
                      <Form
                        id="subscribe-to-event"
                        onSubmit={async (values) => {
                          console.log(values);
                          await subscribeToEventMutation.mutateAsync({
                            data: values,
                          });
                        }}
                        schema={schema}
                      >
                        {({ register, formState }) =>
                          subscribeToEventMutation.isLoading ? (
                            <div className="flex items-center justify-center h-80 flex flex-row space-x-2">
                              <span>{t("justASec")} ..</span>
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              <div className="relative p-6 flex-auto">
                                <div className="flex gap-2 flex-col">
                                  <InputField
                                    label={t("name")}
                                    registration={register("name")}
                                    error={formState.errors["name"]}
                                  />

                                  <InputField
                                    label={t("email")}
                                    registration={register("email")}
                                    error={formState.errors["email"]}
                                  />

                                  <InputField
                                    label={`${t("phone")}`}
                                    registration={register("phone")}
                                    error={formState.errors["phone"]}
                                  />
                                </div>
                              </div>
                              {/*footer*/}
                              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                  className="text-red-500 background-transparent font-bold  px-6 py-2 text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => setShowModal(false)}
                                >
                                  {t("cancel")}
                                </button>
                                <button
                                  className="bg-red-500 text-white active:bg-red-600 font-bold  text-xl px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="submit"
                                >
                                  {t("register")}
                                </button>
                              </div>
                            </>
                          )
                        }
                      </Form>
                    )}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>

        <div className="text-black text-center font-bold mb-10 text-4xl md:text-7xl">
          October 29th, 2022 <br />
          9am - 1pm
        </div>
        <div className="text-red-700 text-center mx-2 md:mx-0 font-bold text-4xl md:text-7xl">
          Kikuyu Market, Kikuyu Stage, Railway Station,
        </div>
        <div className="text-red-700 text-center mx-2 md:mx-0 font-bold text-4xl md:text-7xl">
          Police ST Kiambu, Nairobi, Kenya
        </div>
        <div className="mt-10 border border-8 border-gray-900">
          <figure className="relative transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0">
            <Link to="/latest-events/kampala-uganda">
              <img
                style={{
                  height: "50vh",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={img1}
                alt=" description"
              />
              <figcaption className="absolute bottom-10 px-4 text-lg text-white">
                <span className="font-bold text-md md:text-semi-large">
                  {t("kampalaCleanUp")}
                </span>
                <br />
                <span className="font-bold text-large">
                  Kikaya-Kikulu-Ddungu (LC1) Kisaasi, Kampala, Uganda
                </span>
              </figcaption>
            </Link>
          </figure>
        </div>
        <div className="grid justify-center md:grid-cols-3 gap-4 bg-red-400">
          <img src={Noun444} alt="Noun 444" />
          <img src={Noun445} alt="Noun 445" />
          <img src={Noun446} alt="Noun 446" />
        </div>
      </MainLayout>
    </>
  );
};
