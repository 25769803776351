import React from "react";
import { i18n } from "locales/i18n";
import { useTranslation } from "react-i18next";
import englishFlagIcon from "img/flags/english.png";
import swahiliFlagIcon from "img/flags/swahili.png";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const { t } = useTranslation();

  const handleOnClick = (value) => {
    i18n.changeLanguage(value);
  };

  // get current language
  const currentLanguage = i18n.language;

  return (
    <nav className="bg-red-700 border-gray-200 dark:bg-gray-900">
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl px-4 md:px-6 py-2.5">
        <Link to="/" className="flex items-center">
          <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
            Bulungi Bwansi
          </span>
        </Link>
        <div className="flex items-center">
          <Link
            to="/latest-events"
            className="mr-6 text-sm font-medium text-white dark:text-white hover:underline"
          >
            {t("events")}
          </Link>
          <a
            href="tel:+256706992456"
            className="mr-6 text-sm font-medium text-white dark:text-white hover:underline"
          >
            (256) 706-992-456
          </a>
          <div className="flex items-center md:order-2">
            <a
              href="#!"
              onClick={() =>
                handleOnClick(currentLanguage === "en" ? "sw" : "en")
              }
              className="inline-flex justify-center items-center p-2 text-sm text-white rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              {currentLanguage === "sw" ? (
                <div className="flex flex-row space-x-2 items-center">
                  {" "}
                  <img
                    src={englishFlagIcon}
                    alt="English"
                    className="h-6"
                  />{" "}
                  <span>{t("en")}</span>
                </div>
              ) : (
                <div className="flex flex-row space-x-2 items-center">
                  {" "}
                  <img src={swahiliFlagIcon} className="h-6" alt="Swahili" />
                  <span>{t("sw")}</span>
                </div>
              )}
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
